<template>
  <div>
    <my-dialog title="פרטי הזיכוי" v-model="openModal">
      <template v-slot:content>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">שם ההורה</th>
                <th class="">שם הילד</th>
                <th class="">סכום</th>
                <th class="">תאור</th>
                <th class="">טלפון</th>
                <th class="">כתובת</th>
                <th class="">תאריך עסקה</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="">
                  <div>{{ action.parent.fullName }}</div>
                </td>
                <td class="">
                  <div>{{ action.kid.name }}</div>
                </td>
                <td class="">
                  <span>
                    {{ Math.abs(action.amount) }}
                    <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
                  </span>
                </td>
                <td class="">
                  <div>{{ action.title }}</div>
                </td>
                <td class="">
                  <a :href="`tel:+${action.parent.phone}`">
                    {{ action.parent.phone }}
                  </a>
                </td>
                <td class="">
                  <div>{{ action.parent.address }}</div>
                </td>
                <td class="">
                  <div>
                    {{ dateTimeFormat(action.createdAt) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import { dateTimeFormat } from "@/utils/dayjs";
export default {
  name: "payment-action-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    action: Object,
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    dateTimeFormat,
  },
};
</script>
<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>
