<template>
  <CompanyHeader title="רשימת רכישות">
    <template v-slot:content>
      <v-container>
        <v-card>
          <v-card-title class="d-flex align-center">
            <v-row>
              <v-col :cols="inputSearch ? 8 : 2">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  icon
                  :label="inputSearch ? 'חפוש' : ''"
                  single-line
                  hide-details
                  @focus="inputSearch = true"
                  @blur="inputSearch = false"
                ></v-text-field>
              </v-col>
              <v-col class="center-all">
                <DateRangePicker
                  v-model="dateRange"
                  label="בחר טווח תאריכים"
                  @approved="loadData"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
            :items-per-page="50"
          >
            <template v-slot:item.amount="{ item }">
              {{ Math.abs(item.amount) }}
              <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-space-between">
                <v-icon small color="success" @click="openInfo(item)">
                  mdi-information
                </v-icon>
                <!-- <v-icon small color="primary" @click="editItem(item)">
                  mdi-pencil
                </v-icon> -->
                <v-icon small color="error" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <payment-action-handler
        v-if="openInfoDialog"
        v-model="openInfoDialog"
        :action="current"
      />
    </template>
  </CompanyHeader>
</template>

<script>
import { createSwal, errorSwal, successSwal } from "@/actions";
import CompanyHeader from "@/components/company/CompanyHeader.vue";
import DateRangePicker from "@/components/generics/DateRangePicker.vue";
import dayjs, { dateTimeFormat } from "@/utils/dayjs";

import PaymentActionHandler from "@/components/dialogs/PaymentActionHandler";
// import SwalMixins from "@/mixins/swal";
export default {
  name: "purchasesTable",
  components: { CompanyHeader, DateRangePicker, PaymentActionHandler },
  //   mixins: [SwalMixins],
  data() {
    return {
      loading: false,
      inputSearch: false,
      dateRange: [
        dayjs().subtract(3, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      headers: [
        //TODO: add headers here
        { text: "לקוח", value: "parent.fullName" },
        { text: "סכום", value: "amount" },
        { text: "כותרת", value: "title" },
        { text: "פעולות", value: "actions", width: 44 * 2 },
      ],
      current: null,
      search: "",
      openInfoDialog: false,
      editDialog: false,
    };
  },
  computed: {
    items() {
      return this.$store.getters.actions || [];
    },
  },
  methods: {
    dateTimeFormat,
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("actions/get/company", this.dateRange);
      this.loading = false;
    },
    editItem(item) {
      this.current = item;
      this.editDialog = true;
    },
    openInfo(item) {
      this.current = item;
      this.openInfoDialog = true;
    },
    async deleteItem(item) {
      console.log({ item });
      let { isConfirmed } = await createSwal({
        title: `לבטל את הרכישה של ${item.parent.fullName}${
          item.title ? " שרכש את " + item.title : ""
        }?`,
        icon: "question",
        confirmColor: "red",
      });
      if (isConfirmed) {
        try {
          await this.$store.dispatch("company/cancel-pay", item._id);
          successSwal({ title: "העסקה בוטלה" });
        } catch (e) {
          errorSwal({ title: "העסקה לא בוטלה" });
        }
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
